import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowScenarioNameTile'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowEmptyCardContent'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowErrorScreen'
import { DealCashFlowUsedParametersTable } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowUsedParametersTable'

const CashFlowAssumptionsTile = ({ tileId, isPdfView }) => {
  const {
    data: {
      usedParameters,
      config,
      dealId,
      tileMetaData,
      cashflowScenarioPeriodNotFound,
      sourceRender: { sourcePathSuffix } = {},
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t: tCashFlow } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  if (cashflowScenarioPeriodNotFound) {
    return (
      <ErrorScreen
        title={tCashFlow('loading.error.title')}
        description={tCashFlow('loading.error.description')}
      />
    )
  }

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData?.metaData}
        dealId={dealId}
        isPdfView={isPdfView}
        sourcePathSuffix={sourcePathSuffix}
      />
      {tileMetaData?.metaData ? (
        <DealCashFlowUsedParametersTable
          data={usedParameters}
          config={config}
          titleText={tCashFlow('assumption.table.title')}
          isPdfViewOnDecisionPaper={isPdfView}
        />
      ) : (
        <EmptyCardContent
          illustrationName={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Base}
          title={tCashFlow('no-scenario.selected.title')}
          subtitle={tCashFlow('no-scenario.selected.subtitle')}
        />
      )}
    </>
  )
}

CashFlowAssumptionsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CashFlowAssumptionsTile

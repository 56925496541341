import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CashFlowScenarioOfCheckTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/CashFlowScenarioOfCheckTable'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/EmptyCardContent'
import { CardShowItem } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/card/CardItem'
import CardSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/card/CardSection'

const CashFlowScenarioOfCheckTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'tiles.cashflow-of-check.components.deals.covenants.checked-covenants.cash-flow-scenario',
  })

  const {
    data: {
      cashFlowScenario: { name: cashFlowScenarioName, cashflowScenarioUuid } = {},
      covenantCheck: { keyDate } = {},
      dealUuid,
      dealDisplayId,
      groups,
      calculatedKpis,
      sourceRender: { sourcePathSuffix } = {},
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const cashFlowNameLink = useMemo(
    () =>
      !!dealUuid && !!cashflowScenarioUuid ? (
        <>
          <Link
            id={`${dealUuid}-${cashflowScenarioUuid}-link`}
            href={`/deals/${dealDisplayId}/cashflows/${cashflowScenarioUuid}${sourcePathSuffix}`}
            target="_blank"
          >
            {cashFlowScenarioName}
          </Link>
        </>
      ) : (
        t('name.empty')
      ),
    [dealUuid, cashflowScenarioUuid, dealDisplayId, sourcePathSuffix, cashFlowScenarioName, t],
  )

  return cashflowScenarioUuid ? (
    <>
      <CardSection>
        <CardShowItem id="cashflow-name" label={t('name')} value={cashFlowNameLink} />
      </CardSection>
      <CardSection>
        <CashFlowScenarioOfCheckTable
          keyDate={keyDate}
          calculatedKpis={calculatedKpis}
          groups={groups}
        />
      </CardSection>
    </>
  ) : (
    <EmptyCardContent title={t('empty-text')} subtitle={t('empty-text.subtitle')} />
  )
}

CashFlowScenarioOfCheckTile.propTypes = {
  tileId: PropTypes.string,
}

export default CashFlowScenarioOfCheckTile
